import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Button, useTheme, useMediaQuery } from "@mui/material";

import { StaticImage } from "gatsby-plugin-image";

import styledOri from "styled-components";

// hexagons for fills
import HexOne from "../../images/utterberry-paints/hex-2.svg";

const PREFIX = "ContactUsPage";

const classes = {
  root: `${PREFIX}-root`,
  hexagonWrapper: `${PREFIX}-hexagonWrapper`,
  hexagon: `${PREFIX}-hexagon`,
  content: `${PREFIX}-content`,
  hexagonBanner: `${PREFIX}-hexagonBanner`,
  hexagonSpacing: `${PREFIX}-hexagonSpacing`,
  ratioDummy: `${PREFIX}-ratioDummy`,
  ratioDummyTwo: `${PREFIX}-ratioDummyTwo`,
  hexagonRatioDummy: `${PREFIX}-hexagonRatioDummy`,
  hexagonContainer: `${PREFIX}-hexagonContainer`,
  hexTitle: `${PREFIX}-hexTitle`,
  whiteHexTitle: `${PREFIX}-whiteHexTitle`,
  contactTitle: `${PREFIX}-contactTitle`,
  whiteContactTitle: `${PREFIX}-whiteContactTitle`,
  buttonText: `${PREFIX}-buttonText`,
  hexImage: `${PREFIX}-hexImage`,
  widerHexImage: `${PREFIX}-widerHexImage`,
  whiteDescription: `${PREFIX}-whiteDescription`,
  description: `${PREFIX}-description`,
  hexagonColumn: `${PREFIX}-hexagonColumn`,
  topHexagon: `${PREFIX}-topHexagon`,
  bottomHexagon: `${PREFIX}-bottomHexagon`,
  brandHexagonColumn: `${PREFIX}-brandHexagonColumn`,
  brandHexagonAltColumn: `${PREFIX}-brandHexagonAltColumn`,
  topBrandHexagon: `${PREFIX}-topBrandHexagon`,
  bottomBrandHexagon: `${PREFIX}-bottomBrandHexagon`,
  hexagonFillContainer: `${PREFIX}-hexagonFillContainer`,
  hexOne: `${PREFIX}-hexOne`,
  hexTwo: `${PREFIX}-hexTwo`,
  hexThree: `${PREFIX}-hexThree`,
  hexFour: `${PREFIX}-hexFour`,
  hexFive: `${PREFIX}-hexFive`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100vw",
  padding: 0,
  background: "white",
  paddingBottom: "20px",
  position: "relative",

  [`& .${classes.hexagonWrapper}`]: {
    textAlign: "center",
    position: "relative",
    display: "inline-block",
  },

  [`& .${classes.hexagon}`]: {
    height: "100%",
    width: "calc(100% * 0.57735)",
    display: "inline-block",
    overflow: "hidden",
    zIndex: 0,
    "&::before": {
      position: "absolute",
      top: 0,
      right: "calc((100% / 2) - ((100% * 0.57735) / 2))",
      backgroundColor: "inherit",
      height: "inherit",
      width: "inherit",
      content: '""',
      transform: "rotateZ(60deg)",
      borderTop: "5px solid #540096",
      borderBottom: "5px solid #540096",
      zIndex: 0,
    },
    "&::after": {
      position: "absolute",
      top: 0,
      right: "calc((100% / 2) - ((100% * 0.57735) / 2))",
      backgroundColor: "inherit",
      height: "inherit",
      width: "inherit",
      content: '""',
      transform: "rotateZ(-60deg)",
      borderTop: "5px solid #540096",
      borderBottom: "5px solid #540096",
      zIndex: 0,
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    zIndex: 1,
    position: "relative",
  },

  [`& .${classes.hexagonBanner}`]: {
    flex: 2,
    maxWidth: 900,
    width: "100%",
    position: "relative",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 320,
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: 600,
    },
  },

  [`& .${classes.hexagonSpacing}`]: {
    flex: 1,
    maxWidth: 900,
    width: "100%",
    content: '""',
    position: "relative",
    margin: "8px 0px",
    zIndex: -1,
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 320,
      margin: "28px 0px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: 600,
      margin: "33px 0px",
    },
  },

  [`& .${classes.ratioDummy}`]: {
    marginTop: "20%",
  },

  [`& .${classes.ratioDummyTwo}`]: {
    marginTop: "40%",
  },

  [`& .${classes.hexagonRatioDummy}`]: {
    marginLeft: "100%",
  },

  [`& .${classes.hexagonContainer}`]: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },

  [`& .${classes.hexTitle}`]: {
    margin: "10px 0px",
    padding: "5px 0px",
    borderBottom: "1px solid #540096",
    color: "#540096",
    [theme.breakpoints.down("sm")]: {
      margin: "4px 0px",
      padding: "3px 0px",
    },
  },

  [`& .${classes.whiteHexTitle}`]: {
    margin: "10px 0px",
    padding: "5px 0px",
    borderBottom: "1px solid #fff",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      margin: "4px 0px",
      padding: "3px 0px",
    },
  },

  [`& .${classes.contactTitle}`]: {
    margin: "10px 0px",
    padding: "5px 0px",
    borderBottom: "1px solid #540096",
    color: "#540096",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.whiteContactTitle}`]: {
    margin: "10px 0px",
    padding: "5px 0px",
    borderBottom: "1px solid #fff",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.buttonText}`]: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },

  [`& .${classes.hexImage}`]: {
    width: "100%",
    objectFit: "contain",
  },

  [`& .${classes.widerHexImage}`]: {
    width: "100%",
    objectFit: "contain",
    transform: "scale(1.35)",
  },

  [`& .${classes.whiteDescription}`]: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },

  [`& .${classes.description}`]: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },

  [`& .${classes.hexagonColumn}`]: {
    position: "relative",
    height: "100%",
    margin: "0px -26.5px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px -12.5px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      margin: "0px -20.5px",
    },
  },

  [`& .${classes.topHexagon}`]: {
    position: "absolute",
    transform: "translate(0%, calc(-50% + 2px))",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0%, calc(-50% - 14px))",
    },
    [theme.breakpoints.between("sm", "md")]: {
      transform: "translate(0%, calc(-50% - 18px))",
    },
  },

  [`& .${classes.bottomHexagon}`]: {
    position: "absolute",
    transform: "translate(0%, calc(50% - 2px))",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0%, calc(50% + 14px))",
    },
    [theme.breakpoints.between("sm", "md")]: {
      transform: "translate(0%, calc(50% + 18px))",
    },
  },

  [`& .${classes.brandHexagonColumn}`]: {
    margin: "0px -18px",
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "0px -7px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      margin: "0px -15px",
    },
  },

  [`& .${classes.brandHexagonAltColumn}`]: {
    margin: "0px -10px",
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "0px -6px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      margin: "0px -14px",
    },
  },

  [`& .${classes.topBrandHexagon}`]: {
    position: "absolute",
    transform: "translate(0%, calc(-50% - 0px))",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0%, calc(-50% - 16px))",
    },
    [theme.breakpoints.between("sm", "md")]: {
      transform: "translate(0%, calc(-50% - 20px))",
    },
  },

  [`& .${classes.bottomBrandHexagon}`]: {
    position: "absolute",
    transform: "translate(0%, calc(50% + 0px))",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0%, calc(50% + 16px))",
    },
    [theme.breakpoints.between("sm", "md")]: {
      transform: "translate(0%, calc(50% + 20px))",
    },
  },

  [`& .${classes.hexagonFillContainer}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    zIndex: 0,
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.hexOne}`]: {
    width: 130,
    top: "35%",
    position: "absolute",
    left: -20,
    [theme.breakpoints.down("sm")]: {
      width: 60,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 80,
      top: "36%",
    },
  },

  [`& .${classes.hexTwo}`]: {
    width: 170,
    top: "75%",
    position: "absolute",
    left: -20,
    [theme.breakpoints.down("sm")]: {
      width: 70,
      top: "74.5%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 95,
      top: "76%",
    },
  },

  [`& .${classes.hexThree}`]: {
    position: "absolute",
    width: 260,
    height: 180,
    top: "5%",
    right: -30,
    [theme.breakpoints.down("sm")]: {
      width: 110,
      top: "3%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 150,
    },
  },

  [`& .${classes.hexFour}`]: {
    width: 190,
    top: "45%",
    position: "absolute",
    right: -20,
    [theme.breakpoints.down("sm")]: {
      width: 70,
      top: "46.5%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 100,
      top: "47.5%",
    },
  },

  [`& .${classes.hexFive}`]: {
    width: 190,
    top: "85%",
    position: "absolute",
    right: -20,
    [theme.breakpoints.down("sm")]: {
      width: 70,
      top: "89%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 110,
      top: "89%",
    },
  },
}));

const SMALL_HEX = 160;
const MEDIUM_HEX = 280;
const LARGE_HEX = 360;

const HexagonWrapper = styledOri.div`
  text-align: center;
  position: relative;
  display: inline-block;

  width: 360px;
  height: 360px;

  @media (max-width: 600px) {
    width: 160px;
    height: 160px;
  }

  @media (min-width: 600px) and (max-width: 900px) {
    width: 280px;
    height: 280px;
  }
`;

const HexagonBrandBannerContainer = styledOri.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const HexagonWhiteBannerContainer = styledOri.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 0;
`;

const HexagonDiv = styledOri.div`
  height: 100%;
  width: calc(100% * 0.57735);
  display: inline-block;
  overflow: hidden;
  z-index: 0;
  &:before {
    position: absolute;
    top: 0px;
    right: calc((100% / 2) - ((100% * 0.57735) / 2));
    background-color: inherit;
    height: inherit;
    width: inherit;
    content: "";
    transform: rotateZ(60deg);
    border-top: 5px solid #540096;
    border-bottom: 5px solid #540096;
    z-index: 0;
  }
  &:after {
    position: absolute;
    top: 0px;
    right: calc((100% / 2) - ((100% * 0.57735) / 2));
    background-color: inherit;
    height: inherit;
    width: inherit;
    content: "";
    transform: rotateZ(-60deg);
    border-top: 5px solid #540096;
    border-bottom: 5px solid #540096;
    z-index: 0;
  }
`;

const InnerHexagonDiv = styledOri.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
`;

const MarginDummy = styledOri.div`
  margin-top: 100%;
  width: 360px;

  @media (max-width: 600px) {
    width: 160px;
  }

  @media (min-width: 600px) and (max-width: 900px) {
    width: 280px;
  }
`;

const Content = styledOri.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  z-index: 1;
  position: relative;
`;

const HexagonBanner = styledOri.div`
  flex: 2;
  max-width: 900px;
  width: 100%;
  position: relative;
  height: 100%;
  @media (max-width: 600px) {
    max-width: 320px;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    max-width: 600px;
  }
`;

const HexagonSpacing = styledOri.div`
  flex: 1;
  max-width: 900px;
  width: 100%;
  content: "";
  position: relative;
  margin: 8px 0px;
  z-index: -1;
  pointer-events: none;

  @media (max-width: 600px) {
    max-width: 320px;
    margin: 28px 0px;
  }

  @media (min-width: 600px) and (max-width: 900px) {
    max-width: 600px;
    margin: 33px 0px;
  }
`;

const RatioDummy = styledOri.div`
  margin-top: 20%;
`;

const RatioDummyTwo = styledOri.div`
  margin-top: 40%;
`;

const HexagonContainer = styledOri.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
`;

const HexTitle = styledOri(Typography)`
  margin: 10px 0px 0px 0px;
  padding: 0px 0px;
  color: #540096;
`;

const HexSubTitle = styledOri(Typography)`
  margin: 10px 0px;
  padding: 5px 0px;
  border-bottom: 1px solid #540096;
`;

const WhiteHexTitle = styledOri(Typography)`
  margin: 10px 0px 0px 0px;
  padding: 0px 0px;
  color: #fff;
`;

const WhiteHexSubTitle = styledOri(Typography)`
  margin: 10px 0px;
  padding: 5px 0px;
  border-bottom: 1px solid #fff;
  color: #fff;
`;

const ContactTitle = styledOri(Typography)`
  margin: 10px 0px;
  padding: 5px 0px;
  border-bottom: 1px solid #540096;
  color: #540096;

  @media (max-width: 600px) {
    display: none;
  }
`;

const WhiteContactTitle = styledOri(Typography)`
  margin: 10px 0px;
  padding: 5px 0px;
  border-bottom: 1px solid #fff;
  color: #fff;

  @media (max-width: 600px) {
    display: none;
  }
`;

const ButtonText = styledOri(Button)`
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

const WhiteDescription = styledOri(Typography)`
  color: white;
  margin: 10px 0px 0px;
  @media (max-width: 600px) {
    font-size: 10px;
    margin: 7px 0px 0px;
  }
`;

const Description = styledOri(Typography)`
  margin: 10px 0px 0px;
  @media (max-width: 600px) {
    font-size: 10px;
    margin: 7px 0px 0px;
  }
`;

const HexagonColumn = styledOri.div`
  position: relative;
  height: 100%;
  margin: 0px -26.5px;
  @media (max-width: 600px) {
    margin: 0px -12.5px;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    margin: 0px -20.5px;
  }
`;

const TopHexagon = styledOri.div`
  position: absolute;
  transform: translate(0%, calc(-50% + 2px));
  height: 100%;

  @media (max-width: 600px) {
    transform: translate(0%, calc(-50% - 14px));
  }
  @media (min-width: 600px) and (max-width: 900px) {
    transform: translate(0%, calc(-50% - 18px));
  }
`;

const BottomHexagon = styledOri.div`
  position: absolute;
  transform: translate(0%, calc(50% - 2px));
  height: 100%;

  @media (max-width: 600px) {
    transform: translate(0%, calc(50% + 14px));
  }
  @media (min-width: 600px) and (max-width: 900px) {
    transform: translate(0%, calc(50% + 18px));
  }
`;

const BrandHexagonColumn = styledOri.div`
  margin: 0px -18px;
  height: 100%;
  position: relative;
  @media (max-width: 600px) {
    margin: 0px -7px;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    margin: 0px -15px;
  }
`;

const BrandHexagonAltColumn = styledOri.div`
  margin: 0px -10px;
  height: 100%;
  position: relative;
  @media (max-width: 600px) {
    margin: 0px -6px;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    margin: 0px -14px;
  }
`;

const TopBrandHexagon = styledOri.div`
  position: absolute;
  transform: translate(0%, calc(-50% - 0px));
  height: 100%;

  @media (max-width: 600px) {
    transform: translate(0%, calc(-50% - 16px));
  }
  @media (min-width: 600px) and (max-width: 900px) {
    transform: translate(0%, calc(-50% - 20px));
  }
`;

const BottomBrandHexagon = styledOri.div`
  position: absolute;
  transform: translate(0%, calc(50% + 0px));
  height: 100%;

  @media (max-width: 600px) {
    transform: translate(0%, calc(50% + 16px));
  }
  @media (min-width: 600px) and (max-width: 900px) {
    transform: translate(0%, calc(50% + 20px));
  }
`;

const Hexagon = ({ children, borderTop, borderBottom, color }) => {
  return (
    <div
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      <HexagonWrapper>
        <HexagonDiv
          style={{
            borderTop: borderTop ? "5px solid #540096" : "",
            borderBottom: borderBottom ? "5px solid #540096" : "",
            background: color ? color : "",
            zIndex: 1,
          }}
        >
          {children}
        </HexagonDiv>
      </HexagonWrapper>
    </div>
  );
};

const HexagonBrandBanner = ({ size, children }) => {
  return (
    <HexagonBrandBannerContainer>
      <BrandHexagonAltColumn>
        <Hexagon size={size} color={"#540096"}>
          {Array.isArray(children) && children[1] && (
            <InnerHexagonDiv>{children[1]}</InnerHexagonDiv>
          )}
        </Hexagon>
      </BrandHexagonAltColumn>
      <BrandHexagonColumn>
        <TopBrandHexagon>
          <Hexagon size={size} color={"#540096"}>
            {Array.isArray(children) && children[0] && (
              <InnerHexagonDiv>{children[0]}</InnerHexagonDiv>
            )}
          </Hexagon>
        </TopBrandHexagon>
        <BottomBrandHexagon>
          <Hexagon size={size} color={"#540096"}>
            {Array.isArray(children) && children[2] && (
              <InnerHexagonDiv>{children[2]}</InnerHexagonDiv>
            )}
          </Hexagon>
        </BottomBrandHexagon>
        <MarginDummy />
      </BrandHexagonColumn>
    </HexagonBrandBannerContainer>
  );
};

const HexagonWhiteBanner = ({ size, children }) => {
  return (
    <HexagonWhiteBannerContainer>
      <HexagonColumn>
        <TopHexagon>
          <Hexagon size={size} borderTop>
            {Array.isArray(children) && children[0] && (
              <InnerHexagonDiv>{children[0]}</InnerHexagonDiv>
            )}
          </Hexagon>
        </TopHexagon>
        <BottomHexagon>
          <Hexagon size={size} borderBottom>
            {Array.isArray(children) && children[2] && (
              <InnerHexagonDiv>{children[2]}</InnerHexagonDiv>
            )}
          </Hexagon>
        </BottomHexagon>
        <MarginDummy />
      </HexagonColumn>
      <HexagonColumn>
        <Hexagon size={size} borderBottom borderTop>
          {Array.isArray(children) && children[1] && (
            <InnerHexagonDiv>{children[1]}</InnerHexagonDiv>
          )}
        </Hexagon>
      </HexagonColumn>
    </HexagonWhiteBannerContainer>
  );
};

const ContactUsPage = () => {
  // media breaks for hexagon size
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Root>
      <Content>
        <HexagonSpacing>
          <RatioDummy />
        </HexagonSpacing>
        <HexagonBanner>
          <RatioDummyTwo />
          <HexagonContainer>
            <HexagonWhiteBanner
              size={small ? SMALL_HEX : medium ? MEDIUM_HEX : LARGE_HEX}
              sizeKey={small ? "small" : medium ? "medium" : "large"}
            >
              <div
                style={{
                  zIndex: 100,
                  position: "relative",
                }}
              >
                <HexTitle variant={"h4"}>London</HexTitle>
                {!small && (
                  <HexSubTitle
                    variant={"caption"}
                    style={{
                      fontWeight: "bold",
                      color: "#540096",
                    }}
                  >
                    124 City Road, EC1V 2NX
                  </HexSubTitle>
                )}
                {small && (
                  <Description>
                    Located at 124 City Road, London is the centre of
                    UtterBerry’s UK operations for hardware and software.
                  </Description>
                )}
                {!small && (
                  <Typography style={{ marginTop: "10px" }}>
                    Our London office is the centre of UtterBerry’s UK
                    operations, home to our hardware and software development as
                    well as serving as our main office. Please click the button
                    below to contact UtterBerry about our London office and
                    organising a meeting.
                  </Typography>
                )}
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  padding: "4px",
                }}
              >
                <StaticImage
                  src={"../../images/contact-images/london-building.webp"}
                  className={classes.hexImage}
                  objectFit={"contain"}
                  placeholder={"blurred"}
                  alt={"London Office"}
                  width={150}
                  height={250}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                }}
              >
                <ContactTitle variant={"h5"}>
                  Want to get in touch?
                </ContactTitle>
                <ButtonText
                  variant='contained'
                  color='primary'
                  href={"mailto:utterberry@utterberry.com"}
                >
                  Contact Us
                </ButtonText>
              </div>
            </HexagonWhiteBanner>
          </HexagonContainer>
        </HexagonBanner>
        <HexagonSpacing>
          <RatioDummy />
        </HexagonSpacing>
        <HexagonBanner>
          <RatioDummyTwo />
          <HexagonContainer>
            <HexagonBrandBanner
              size={small ? SMALL_HEX : medium ? MEDIUM_HEX : LARGE_HEX}
              sizeKey={small ? "small" : medium ? "medium" : "large"}
            >
              <div>
                <WhiteHexTitle variant={"h4"}>Leeds</WhiteHexTitle>
                {!small && (
                  <WhiteHexSubTitle
                    variant={"caption"}
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    32 Sovereign St, LS1 4BJ
                  </WhiteHexSubTitle>
                )}
                {small && (
                  <WhiteDescription>
                    {`Located at 32 Sovereign St, LS1 4BJ is the Manufacturing & Innovation hub,
                    with hardware, manufacturing and building management roles.`}
                  </WhiteDescription>
                )}
                {!small && (
                  <WhiteDescription>
                    {`Our new office in Leeds is
                    home to our Manufacturing & Innovation hub and marks our expansion
                    into the north of the UK. We are currently looking to fill
                    many hardware and manufacturing jobs as well as building
                    management roles. Please click the button below to contact
                    UtterBerry about our Leeds operation.`}
                  </WhiteDescription>
                )}
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StaticImage
                  src={"../../images/contact-images/leeds-building-nobg.webp"}
                  className={classes.hexImage}
                  objectFit={"contain"}
                  placeholder={"blurred"}
                  alt={"Leeds Office"}
                  width={160}
                  height={220}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                }}
              >
                <WhiteContactTitle
                  variant={"h5"}
                  className={classes.whiteContactTitle}
                >
                  Want to get in touch?
                </WhiteContactTitle>
                <ButtonText
                  variant='contained'
                  color='white'
                  href={"mailto:utterberry@utterberry.com"}
                >
                  Contact Us
                </ButtonText>
              </div>
            </HexagonBrandBanner>
          </HexagonContainer>
        </HexagonBanner>
        <HexagonSpacing>
          <RatioDummy />
        </HexagonSpacing>
        <HexagonBanner>
          <RatioDummyTwo />
          <HexagonContainer>
            <HexagonWhiteBanner
              size={small ? SMALL_HEX : medium ? MEDIUM_HEX : LARGE_HEX}
              sizeKey={small ? "small" : medium ? "medium" : "large"}
            >
              <div
                style={{
                  zIndex: 100,
                  position: "relative",
                }}
              >
                <HexTitle
                  variant={"h4"}
                  style={{
                    borderBottom: "1px solid #540096",
                    marginBottom: "9px",
                  }}
                >
                  Sales
                </HexTitle>
                {small && (
                  <Description>
                    To find out what our innovative technology can do to help
                    you contact a member of our sales team.
                  </Description>
                )}
                {!small && (
                  <Typography>
                    The UtterBerry system can be used to facilitate a wide range
                    of corporate, industrial and commercial scenarios. To find
                    out what our innovative technology can do to help you please
                    click the button below and get in contact with a member of
                    our sales team.
                  </Typography>
                )}
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StaticImage
                  src={"../../images/contact-images/sales-image.webp"}
                  className={classes.widerHexImage}
                  objectFit={"contain"}
                  placeholder={"blurred"}
                  alt={"Sales"}
                  width={160}
                  height={80}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                }}
              >
                <ContactTitle variant={"h5"}>
                  Want to get in touch?
                </ContactTitle>
                <ButtonText
                  variant='contained'
                  color='primary'
                  href={"mailto:sales@utterberry.com"}
                >
                  Contact Us
                </ButtonText>
              </div>
            </HexagonWhiteBanner>
          </HexagonContainer>
        </HexagonBanner>
        <HexagonSpacing>
          <RatioDummy />
        </HexagonSpacing>
      </Content>
      <div className={classes.hexagonFillContainer}>
        <img src={HexOne} className={classes.hexOne} alt={"Hexagon Filler"} />
        <StaticImage
          src={"../../images/utterberry-paints/hex-5.webp"}
          className={classes.hexTwo}
          objectFit={"contain"}
          placeholder={"blurred"}
          alt={"Hexagon Filler"}
          width={195}
          height={243}
        />
        <StaticImage
          src={"../../images/utterberry-paints/multi-hex-1.webp"}
          className={classes.hexThree}
          objectFit={"contain"}
          placeholder={"blurred"}
          alt={"Hexagon Filler"}
          width={150}
          height={120}
        />
        <StaticImage
          src={"../../images/utterberry-paints/multi-hex-2.webp"}
          className={classes.hexFour}
          objectFit={"contain"}
          placeholder={"blurred"}
          alt={"Hexagon Filler"}
          width={220}
          height={250}
        />
        <StaticImage
          src={"../../images/utterberry-paints/multi-hex-3.webp"}
          className={classes.hexFive}
          objectFit={"contain"}
          placeholder={"blurred"}
          alt={"Hexagon Filler"}
          width={250}
          height={315}
        />
      </div>
    </Root>
  );
};

export default ContactUsPage;
