import * as React from "react";

import styled from "styled-components";
import { Helmet } from "react-helmet";

// sections
import ContactUsPage from "../../components/contact-page/index";

const Root = styled.main`
  padding: 0px;
  width: 100vw;
  background: white;
  margin-top: 65px;

  @media (max-width: 600px) {
    margin-top: 57px;
  }

  @media (max-height: 500px) {
    margin-top: 49px;
  }
`;

// markup
const IndexPage = () => {
  return (
    <Root>
      <ContactUsPage />
    </Root>
  );
};

export function Head() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>Contact Us - UtterBerry</title>
      <meta
        name='description'
        content='Contact Us - UtterBerry is a provider of Artificially Intelligent wireless sensor systems for the development of Smart Cities and the revolutionisation of sensing capabilities.'
      ></meta>
    </Helmet>
  );
}

export default IndexPage;
